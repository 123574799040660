import React, {useContext, useEffect} from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'

import { Layout, Navbar } from '../Layout'
import { ConfigurationPanelStack } from '../components/Game/ConfigurationPanelStack'
import { withFirebaseAuthentication } from '../components/hocs/withFirebaseAuthentication'
import { FirebaseContext } from '../components/Firebase'

const ConfigurationPage = () => {

	// const { firebase } = useContext(FirebaseContext)

	// useEffect(() => {
	// 	if (!firebase?.auth?.currentUser) {
	// 	navigate('/login')
	// } else if (!['AAjVa702VSPrdB3HozlN6tIQ9vI2', '0GpSvHdqKCOZHJjwAyE6i5fkbhR2'].includes(firebase.auth.currentUser.uid )){
	// 	navigate('/')
	// 	alert('Utter is under maintenance. Please check back later.')
	// }
	// }, [])
	
	return (
		<Layout
			title="Utter"
			navbar={<Navbar position="absolute"/>}
			content={<ConfigurationPanelStack /> }
			footer={null}
		/>
	)
}


ConfigurationPage.propTypes = {
	location: PropTypes.object
}

export default ConfigurationPage
