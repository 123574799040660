import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'

import { Card, PanelStack, Typography, NavigationButton, FeedbackButton } from '../primitives'
import { GameModes } from '../../../utils/models'
import { useGame } from './GameProvider'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const SetupCard = styled(Card)`
  border-radius: 5px !important;
  background: ${({theme}) => theme.name === 'light' ? 'rgba(222,222,227,1)' : 'rgba(18,18,20,1)'} !important;
  border: none !important;
  ul {
    list-style-position: outside;
    list-style-type: circle;
  }
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1) !important;
	width: 100%;
`
const SetupForm = styled(PanelStack)`
  .bp3-panel-stack-view {
    background-color: transparent;
  }
`
const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const ConfigurationPanelStack = () => {
	const [game, setGame] = useGame()
	const [panelStack, setPanelStack] = useState(null)

	useEffect(() => {
		resetGame()
	}, [])

	useEffect(() => {
		if(game && !panelStack) { 			
			setPanelStack(
				game.hideWelcome()
					? game.getFormPanels(game.mode).slice(0,2) // start from the second element
					: game.getFormPanels(game.mode).slice(0,1) // start from the first element
			)
		}
	}, [game])
	
	const addToPanelStack = (newPanel) => {
		setPanelStack((prev) => ([...prev, newPanel]))
	}

	const removeFromPanelStack = () => {
		setPanelStack((prev) => (prev.slice(0, -1))) // remove one element from the end
	}

	const backToFirst = () => {
		setPanelStack(game.getFormPanels(game.mode).slice(0, 1))
	}

	const canStartGame = () => {
		if(game.mode.id === GameModes.CATEGORY.id) return true
		if (panelStack.length !== game.getFormPanels(game.mode).length) return false
		return game.canStartGame(game.mode, game.players, game.twitterHandles)
	}

	const hasMinimumPlayers = () => {
		if (panelStack.length !== 2) return true
		return game.players.length >= 1
	}

	const startGame = () => {
		navigate('/game')
	}

	const resetGame = () => {
		setGame(prev => ({
			...prev,
			score: {},
			currentRound: null,
			tweets: null,
			turnCount: 0,
			hasBegun: false,
			gameOver: false,
		}))
	}

	return (
		<>
			{
				panelStack
					? <Wrapper>
						<SetupCard>
							<SetupForm
								onOpen={addToPanelStack}
								onClose={removeFromPanelStack}
								stack={panelStack}
							/>
							<Footer>
								{
									panelStack.length > 1
										?<FeedbackButton
											onClick={backToFirst}
										>
											<Typography variant="body" weight="bold" color="#000" className="text-center m-0" >View rules</Typography>
										</FeedbackButton>
										: null
								}
								{
									panelStack.length > 2 && panelStack.length === game.getFormPanels(game.mode).length
										?<FeedbackButton
											onClick={removeFromPanelStack}
										>
											<Typography variant="body" weight="bold" color="#000" className="text-center m-0" >Back</Typography>
										</FeedbackButton>
										: null
								}
								{
									(game.mode.id === GameModes.TEAM.id
										&& panelStack.length !== game.getFormPanels(game.mode).length
										&& hasMinimumPlayers())
										?<NavigationButton
											onClick={() => addToPanelStack(game.getFormPanels(game.mode)[panelStack.length])}
										>
											<Typography variant="body" weight="bold" color="#FFF" className="text-center m-0">Next</Typography>
										</NavigationButton>
										: null
								}
								{
									canStartGame()
										?<NavigationButton onClick={() => startGame()}>
											<Typography variant="body" weight="bold" color="#FFF" className="text-center m-0" >Start</Typography>
										</NavigationButton>
										: null
								}
							</Footer>
						</SetupCard>
					</Wrapper>
					: null
			}
		</>
	)
}
